<template>
  <div class="page-container drawer-page-content" id="PageContainer">
    <main
      data-router-wrapper=""
      class="main-content js-focus-hidden"
      id="MainContent"
      role="main"
      tabindex="-1"
    >
      <div data-router-view="Serino">
        <div class="shopify-section" v-for="(item, index) in lists" :key="index">
          <section class="hp-module">
            <div class="hp-module__wrapper">
              <div class="hp-module__text">
                <p>{{ item.title.en }}</p>
              </div>
              <div class="hp-module__imgs" :class="[item.position]">
                <div
                  class="hp-module__element"
                  v-for="(img, i) in item.srcs"
                  :key="i"
                  :class="[`el-${i + 1}`]"
                >
                  <a href="#">
                    <picture class="hp-module__figure">
                      <source media="(min-width: 501px) and (max-width: 767px)" :srcset="img" />
                      <source media="(max-width: 500px)" :srcset="img" />
                      <img class="hp-module__image" :src="img" />
                    </picture>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
/* eslint-disable global-require */
import init from '@/libs/automove/init';
import '@/assets/scss/automove.scss';

export default {
  name: 'views-auto-move',
  data() {
    return {
      projects: [
        {
          type: 'module-type--6',
          src: [
            require('@/assets/images/automove/him-blazer-homepage.jpg'),
            require('@/assets/images/automove/her_jeanjacket_back.jpg'),
          ],
        },
        {
          type: 'module-type--6',
          src: [
            require('@/assets/images/automove/her_jeanjacket_lips.jpg'),
            require('@/assets/images/automove/him_buttondown_home.jpg'),
          ],
        },
        {
          type: 'module-type--5',
          src: [require('@/assets/images/automove/him_jeanjacket_back.jpg')],
        },
        {
          type: 'module-type--7',
          src: [
            require('@/assets/images/automove/her_shirt_homepage_0f6a2688-0cbb-452d-929e-4c60a5126868.jpg'),
            require('@/assets/images/automove/him.jeanjacket.collar.jpg'),
          ],
        },
        {
          type: 'module-type--3',
          src: [
            require('@/assets/images/automove/her_blazer_navy.jpg'),
            require('@/assets/images/automove/him_jeanjacket_slouched.jpg'),
          ],
        },
        {
          type: 'module-type--1',
          src: [
            require('@/assets/images/automove/her_slipdress_front.jpg'),
            require('@/assets/images/automove/her_slipdress_back.jpg'),
          ],
        },
      ],
      lists: [
        {
          srcs: [require('@/assets/images/demo1/p1/1.png')],
          position: 'right',
          title: {
            en: 'Zibo Vanke Jade East Underground Club',
            cn: '淄博万科翡翠东第地下会所',
          },
        },
        {
          srcs: [
            require('@/assets/images/demo1/p1/2.png'),
            require('@/assets/images/demo1/p1/3.png'),
          ],
          position: 'between',
          title: {
            en: 'Zhengrong Hour Light Life Museum',
            cn: '正荣青云宸院展示中心',
          },
        },
        {
          srcs: [require('@/assets/images/demo1/p1/4.png')],
          position: 'right',
          title: {
            en: 'China Resources Eternal Bea',
            cn: '沈阳华润瑞府展示中心',
          },
        },
        {
          srcs: [
            require('@/assets/images/demo1/p1/7.png'),
            require('@/assets/images/demo1/p1/8.png'),
          ],
          position: 'left',
          title: {
            en: 'Ronshine Quiet Leisure Garden',
            cn: '融信·青澜园展示中心',
          },
        },
        {
          srcs: [require('@/assets/images/demo1/p1/9.png')],
          position: 'center',
          title: {
            en: 'Rongxin Country Garden',
            cn: '融信.碧桂园大境风华售楼处',
          },
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      init();
    });
  },
};
</script>

<style></style>
